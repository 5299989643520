<template>
    <div class="lxwm">
        <ContactUs></ContactUs>
        <div class="lxwm_m">
            <div class="part_header">
                <router-link to="/">首页</router-link> > 联系我们
            </div>
            <div class="lxwm_content">
                <div class="adress">
                    <h3>瞻世趣科技发展（北京）有限公司</h3>
                    <p>地址：北京市丰台区中核路1号院1号楼303</p>
                    <p>电话：010-83629320</p>
                    <p>网址：www.zingsq.com</p>
                    <p>邮箱：info@zingsq.com</p>
                </div>
                <div class="amap-wrapper">
                    <el-amap class="amap-box" :vid="'amap-vue'" :zoom="zoom" :center="center">
                        <el-amap-marker :position="marker.position" :visible="marker.visible"
                            :draggable="marker.draggable" :vid="1"></el-amap-marker>
                    </el-amap>
                </div>
            </div>
            <div class="leave" ref="lea">
                <p>留言标题</p>
                <input ref="int" type="text">
                <p>留言内容</p>
                <textarea ref="int2" name="" id="" cols="30" rows="10"></textarea>
                <p>联系邮箱</p>
                <input ref="int3" type="text">
                <p>验证码</p>
                <div class="code">
                    <input ref="int4" maxlength="4" type="text" class="code_num">
                    <div class="code_img">
                        <span v-for="(item,index) in list" :key="index">{{item}}</span>
                    </div>
                    <a @click="con()">看不清，换一张</a>
                </div>
                <div class="down">
                    <button class="push" @click="push()">提交</button>
                    <button class="cancel" @click="cancel()">取消</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ContactUs from "@/components/common/contactus.vue";
    export default {
        name: 'Gywm',
        data() {
            return {
                zoom: 13, // 地图缩放等级
                center: [116.285412, 39.832888], // 定位坐标
                // 添加气泡
                marker: {
                    position: [116.285412, 39.832888],
                    visible: true, // 气泡是否显示
                    draggable: false, // 气泡是否可以拖动
                    template: '<span>1</span>',
                },
                asd:0,
                bsd:0,
                list:[],
                code:0,
                code_img:0
            };
        },
        components: {
            ContactUs
        },
        created(){
            this.con();
        },
        mounted(){
            
            
        },
        methods: {
            con(){
                this.list = [];
                for (let i = 0; i < 4; i++) {
                    let num = Math.floor(Math.random()*10);
                    this.list.push(num);
                }
                this.code_img = this.list.join('');
            },
            clear(){
                this.$refs.int.value = '';
                this.$refs.int2.value = '';
                this.$refs.int3.value = '';
                this.$refs.int4.value = '';
            },
            push(){
                
                this.code = this.$refs.int4.value;
                if (this.code == this.code_img) {
                    alert("已提交！");
                    this.clear();
                    // this.$refs.int4.value = '';
                }else{
                    alert("请输入正确的验证码！")
                }
                console.log(this.code,this.code_img);
                
            },
            cancel(){
                this.clear();
                alert("已取消！");
            }
        }
    }
</script>
<style lang="less" scoped>
    .lxwm {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;

        .lxwm_m {
            width: 700px;
            margin-bottom: 60px;
        }

        .lxwm_content {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .adress {
                width: 40%;

                h3 {
                    font-family: 'Microsoft YaHei';
                    font-size: 18px;
                    color: rgb(51, 51, 51);
                    margin-bottom: 25px;
                }

                p {
                    font-family: 'Microsoft YaHei';
                    font-size: 12px;
                    color: rgb(51, 51, 51);
                    line-height: 2em;
                }
            }

            .amap-wrapper {
                width: 55%;
            }
        }

        .leave {
            width: 100%;
            margin-top: 40px;

            p {
                height: 40px;
                line-height: 40px;
                text-align: left;
                color: #4D4D4D;
                font-size: 14px;
            }

            input {
                padding-left: 5px;
                width: 100%;
                height: 35px;
                line-height: 35px;
                border: 1px solid #dcdcdc;
                background-color: #fafafa;
                color: #666;
                font-size: 13px;
            }

            textarea {
                padding: 5px;
                width: 100%;
                height: 102px;
                border: 1px solid #dcdcdc;
                background-color: #fafafa;
                color: #666;
                font-size: 13px;
            }

            .code {
                display: flex;
                justify-content: space-between;
                width: 43%;
                .code_num {
                    padding: 0;
                    width: 100px;
                    text-align: center;
                    letter-spacing: 0.2em;
                    font-size: 15px;
                }
                .code_img {
                    width: 90px;
                    height: 35px;
                    padding: 0 15px;
                    background: url('../../assets/timg.jpg') no-repeat;
                    background-size: 100% 100%;
                    color: #fff;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    font-size: 18px;
                }
                a {
                    font-size: 13px;
                    cursor: pointer;
                    display: block;
                    padding-top: 16px;
                }
                a:hover{
                    color: #000;
                    text-decoration: underline;

                }
            }
            .down{
                text-align: right;
                margin-top: 30px;
                button{
                    background: #005e9b;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    color: #fff;
                    border: none;
                    margin-left: 20px;
                }
            }
        }
    }
</style>