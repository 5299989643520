<template>
    <div class="sy">
        <div class="left">
            <div class="new_us">
                <div class="new">
                    <div class="theme_header">
                        <div class="theme_left">新闻动态/NEWS</div>
                        <div class="theme_right">
                            <router-link to='/xwdt'>MORE+</router-link>
                        </div>
                    </div>
                    <div class="news_list">
                        <ul>
                            <li v-for="(item,index) in top_list.slice(0,10)" :key="index">
                                <router-link :to="'/news/'+index">{{item.theme}}</router-link>
                            </li>
                        </ul>
                        <!-- <p v-for="(item,index) in top_list.slice(0,9)"  :key='index'>{{item.theme}}</p> -->
                        <!-- <router-link v-for="(item,index) in top_list" :key='index' to='/news'>
                            {{item.theme}}
                        </router-link> -->
                    </div>
                </div>
                <div class="us">
                    <div class="theme_header">
                        <div class="theme_left">关于我们/ABOUT US</div>
                        <div class="theme_right">
                            <router-link to='/gywm'>MORE+</router-link>
                        </div>
                    </div>
                    <div class="us_m">
                        <div class="us_m_txt">
                            <p>瞻世趣是一家由十年以上彩票运营经验行业精英所组成的面向彩票发行、管理、销售机构以及行业公司提供各项服务的专业企业。凭借瞻世趣的丰富经验能够准确的把握彩票政策风向和市场脉搏，尤其擅长运用互联网思维针对“后互联网时代”提供为彩票发行、销售、推广提供完整的彩票解决方案并加以实施。
                            </p>
                            <p>新的彩票行业格局正在酝酿，凭借瞻世趣的彩票专业能力，我们期望与合作伙伴共同开拓未来的中国彩票市场。</p>
                        </div>
                        <div class="us_m_img"><img src="../../assets/about_us_index.png" alt=""></div>
                    </div>
                </div>
            </div>
            <div class="code">
                <dl>
                    <dt><img src="../../assets/zsq_wechat.jpg" alt=""></dt>
                    <dd>瞻世趣官方微信</dd>
                </dl>
                <dl>
                    <dt><img src="../../assets/funhainan_wechat.jpg" alt=""></dt>
                    <dd>乐趣海南官方微信</dd>
                </dl>
            </div>
        </div>
        <div class="right">
            <div class="bus">
                <div class="theme_header">
                    <div class="theme_left">业务介绍/BUSINESS</div>
                    <div class="theme_right">
                        <router-link to='/ywjs'>MORE+</router-link>
                    </div>
                </div>
                <div class="bus_link">
                    <div class="bus_link1"><a href="http://www.funhainan.com/"><img src="../../assets/bus_index.png"
                                alt=""></a></div>
                    <div class="bus_link2">
                        <router-link to='/ywjs'>彩票业务优化</router-link>
                    </div>
                    <div class="bus_link2">
                        <router-link to='/ywjs'>游戏玩法设计与运营</router-link>
                    </div>
                </div>
            </div>
            <div class="con">
                <div class="theme_header">
                    <div class="theme_left">联系我们/CONTACT</div>
                    <div class="theme_right">
                        <router-link to='/lxwm'>MORE+</router-link>
                    </div>
                </div>
                <div class="con_m">
                    <p>瞻世趣科技发展（北京）有限公司</p>
                    <p>地址：北京市丰台区中核路1号院1号楼303</p>
                    <p>电话：010-83629320</p>
                    <p>网址：www.zingsq.com </p>
                    <p>邮箱：info@zingsq.com</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Sy",
        props: {
            top_list: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

            }
        }
    }
</script>
<style lang="less" scoped>
    .sy {
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        margin-bottom: 100px;
    }

    .left {
        width: 66%;
    }

    .right {
        width: 30%;
    }

    .new_us {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;

        >div {
            width: 47%;
        }
    }

    .theme_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 30px 0 10px;

        .theme_left {
            font-size: 16px;
            font-weight: bold;
            color: rgb(44, 90, 168);
        }

        .theme_right {
            font-size: 10px;
            color: rgb(127, 127, 127);
            font-family: 'Microsoft YaHei';
        }
    }

    .us_m_txt {
        line-height: 1.5;
        font-size: 14px;
        text-indent: 2em;
        color: rgb(127, 127, 127);
        padding: 20px 0 0;
    }

    .us_m_img {
        img {
            width: 100%;
        }

        margin-top: 10px;
    }

    .bus_link {
        >div {
            margin: 7px 0;
        }

        .bus_link2 {
            height: 65px;
            background: url('../../assets/bus_bg_index.png') no-repeat;

            a {
                display: block;
                line-height: 65px;
                text-indent: 2em;
                font-weight: bold;
                font-size: 16px;
                color: rgb(255, 255, 255);
            }
        }
    }

    .con_m {
        font-size: 13px;
        margin-top: 15px;

        p {
            line-height: 1.5;
        }
    }

    .news_list {
        padding-top: 20px;

        ul {
            li,p {
                list-style-type: none;
                font-size: 14px;
                text-indent: 1em;
                height: 25px;
                line-height: 25px;
                overflow: hidden;
                word-break: break-all;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                /*!autoprefixer:off*/
                -webkit-box-orient: vertical;

                /*autoprefixer:on*/
                a{
                    text-decoration: underline;
                    // text-shadow: 2px 2px 2px #000;
                }
                a:hover {
                    color: blue;
                }
            }
        }
    }

    .code {
        display: flex;

        dl {
            margin: 10px 30px;
            ;

            dt {
                width: 89px;
                height: 89px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            dd {
                color: #000;
                font-size: 12px;
                text-align: center;
            }
        }
    }
</style>