<template>
    <div class="contactus">
        <h3>联系我们</h3>
        <img src="../../assets/lxwm_left.jpeg" alt="">
        <div>
            <h4>瞻世趣科技发展（北京）有限公司</h4>
            <p>地址：北京市丰台区中核路1号院1号楼303</p>
            <p>电话：010-83629320</p>
            <p>网址：www.zingsq.com </p>
            <p>邮箱：info@zingsq.com</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ContactUs'
    }
</script>
<style lang="less" scoped>
    .contactus {
        width: 250px;
        height: 260px;
        background: #2c5aa8;
        h3 {
            color: #fff;
            text-align: center;
            height: 60px;
            line-height: 60px;
        }

        img {
            width: 100%;
        }

        div {
            text-indent: .5em;
            width: 95%;
            margin: 10px auto 0;
            h4 {
                font-size: 14px;
                line-height: 25px;
                color: rgb(198, 217, 240);
            }

            p {
                font-size: 12px;
                line-height: 1.5;
                color: #fff;

            }

            p:last-of-type {
                border-bottom: 1px dotted rgb(218, 218, 218);
                padding-bottom: 8px;
            }
        }


    }
</style>