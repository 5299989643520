<template>
    <div id="app">
        <div class="top_line"></div>

        <div class="main">
            <div class="header">
                <div class="logo" @click="gotosy()"><img src="./assets/zsq_logo.png" alt=""></div>
                <div id="nav">
                    <router-link to="/">首页</router-link>
                    <router-link to="/gywm">关于我们</router-link>
                    <router-link to="/xwdt">新闻动态</router-link>
                    <router-link to="/ywjs">业务介绍</router-link>
                    <router-link to="/hzhb">合作伙伴</router-link>
                    <router-link to="/gzjh">工作机会</router-link>
                    <router-link to="/lxwm">联系我们</router-link>
                </div>
            </div>
            <div class="banner">
                <el-carousel :interval="5000" arrow="always" height="400px" indicator-position="none">
                    <el-carousel-item v-for="item in imagesbox" :key="item.id">
                        <img :src="item.src" alt="" class="banner_img">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <router-view :top_list='new_list' :key="$route.fullPath"/>
        </div>

        <div class="footer">
            <p><span>版权所有© 瞻世趣科技发展（北京）有限公司</span> <a href="https://beian.miit.gov.cn/" target="_blank" style='color:white'><span>京ICP 备16048112号-1</span></a></p>
            <p>Zing Super Quire Technology & Development Co., ltd.</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: "App",
        data() {
            return {
                imagesbox: [{
                        id: 0,
                        src: require("./assets/index_01.jpg")
                    }, {
                        id: 1,
                        src: require("./assets/index_02.jpg")
                    },
                    {
                        id: 2,
                        src: require("./assets/index_03.jpg")
                    },
                    {
                        id: 3,
                        src: require("./assets/index_04.jpg")
                    }
                ],
                new_list: [{
                        id: 0,
                        theme: '乐趣海南-狗狗冲冲冲竞猜介绍',
                        time: '2017-06-27'
                    },
                    {
                        id: 1,
                        theme: '“乐趣海南”试运营上线',
                        time: '2017-06-27'
                    },
                    {
                        id: 2,
                        theme: '融合市场开展即开彩票精细化营销',
                        time: '2017-06-27'
                    },
                    {
                        id: 3,
                        theme: '瞻世趣CEO应邀参加长江证券投资交流活动',
                        time: '2017-02-24'
                    },
                    {
                        id: 4,
                        theme: '彩票营销与管理专业2017届毕业生毕业论文（调研报告）答辩会圆满举行',
                        time: '2017-01-06'
                    },
                    {
                        id: 5,
                        theme: '瞻世趣获丰台区政府颁发“安全生产标准化”证书',
                        time: '2016-12-30'
                    },
                    {
                        id: 6,
                        theme: '浅谈彩票游戏玩法的申报',
                        time: '2016-10-08'
                    },
                    {
                        id: 7,
                        theme: '京东主题体彩即开彩票上市',
                        time: '2016-09-30'
                    },
                    {
                        id: 8,
                        theme: '英国彩票商DJI改名BNN，谋求多元化',
                        time: '2016-09-27'
                    },
                    {
                        id: 9,
                        theme: '魔方元获得华人文化融资 颜志应邀出席',
                        time: '2016-09-02'
                    },
                    {
                        id: 10,
                        theme: '2016雷达之夜圆满收官',
                        time: '2016-09-02'
                    },
                    {
                        id: 11,
                        theme: '新浪彩通：怎么看取消彩票投注站？',
                        time: '2016-08-31'
                    },
                    {
                        id: 12,
                        theme: '彩票行业沙龙圆满落幕 行业智库成立',
                        time: '2016-04-22'
                    }
                ]
            }
        },
        methods: {
            // 跳转
            gotosy() {
                this.$router.push({
                    path: '/'
                })
            },
            gotogywm() {
                this.$router.push({
                    path: '/gywm'
                })
            },
            gotoxwdt() {
                this.$router.push({
                    path: '/xwdt'
                })
            },
            gotoywjs() {
                this.$router.push({
                    path: '/ywjs'
                })
            },
            gotohzhb() {
                this.$router.push({
                    path: '/hzhb'
                })
            },
            gotogzjh() {
                this.$router.push({
                    path: '/gzjh'
                })
            },
            gotolxwm() {
                this.$router.push({
                    path: '/lxwm'
                })
            }
        }


    }
</script>
<style lang="less">
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        // color: rgb(127, 127, 127);
        font-family: Microsoft YaHei;
    }

    #app {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        // text-align: center;
        color: rgb(127, 127, 127);
        position: relative;
        padding-bottom: 60px;
    }

    a {
        text-decoration: none;
        color: rgb(127, 127, 127);
    }

    .top_line {
        height: 20px;
        background: #2c5aa8;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;
        background: #2c5aa8;
        color: rgb(198, 217, 240);
        font-family: "Microsoft YaHei";
        font-size: 12px;
        text-align: center;
        line-height: 1.5;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;

        p {
            width: 100%;
        }
    }

    .main {
        width: 1000px;
        margin: 0 auto 15px;
    }

    .header {
        height: 95px;
        display: flex;
        justify-content: space-between;

        .logo {
            width: 159px;
            height: 89px;
            align-self: flex-end;

            img {
                width: 100%;
                height: 100%;
            }
        }

        #nav {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 740px;
            padding-top: 20px;

            a {
                color: #2c5aa8;
                font-size: 18px;
                text-decoration: none;

                &.router-link-exact-active {
                    color: #444444;
                    font-weight: bold;
                }

            }

            a:hover {
                font-weight: bold;
                text-shadow: 0px 2px 5px #a0b2e4;

            }
        }

    }

    .banner {
        width: 1000px;
        height: 400px;

        .item {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .banner_img {
        width: 100%;
        height: 100%;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .el-icon-arrow-right {
        font-size: 30px;
    }

    .el-icon-arrow-left {
        font-size: 30px;

    }

    .part_header {
        color: rgb(44, 90, 168);
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #CCC;
        height: 45px;
        line-height: 45px;

        a {
            font-weight: 400;
            font-size: 14px;
        }
    }
</style>