import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Sy from "../components/sy/sy.vue";
import Lxwm from "../components/lxwm/lxwm.vue";


Vue.use(VueRouter);

const routes = [
    // {
    //     path: "/",
    //     name: "home",
    //     component: Home
    // },
    // {
    //     path: "/about",
    //     name: "about",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import( /* webpackChunkName: "about" */ "../views/About.vue")
    // },
    {
        path: "/",
        name: "sy",
        component: Sy
    },
    {
        path: "/gywm",
        name: "gywm",
        component: () =>
            import("../components/gywm/gywm.vue")
    },
    {
        path: "/xwdt",
        name: "xwdt",
        component: () =>
            import("../components/xwdt/xwdt.vue")
    },
    {
        path: "/ywjs",
        name: "ywjs",
        component: () =>
            import("../components/ywjs/ywjs.vue")
    },
    {
        path: "/hzhb",
        name: "hzhb",
        component: () =>
            import("../components/hzhb/hzhb.vue")
    },
    {
        path: "/gzjh",
        name: "gzjh",
        component: () =>
            import("../components/gzjh/gzjh.vue")
    },
    {
        path: "/lxwm",
        name: "lxwm",
        component: Lxwm
    },
    {
        path: "/news/:id",
        name: "news",
        component: () =>
            import("../components/news/news.vue")
    },
];

const router = new VueRouter({
    routes
});

export default router;